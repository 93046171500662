.delete-button {
  margin-left: 10px;
}

.save-button {
  margin-left: 10px;
}

.complete-button {
  margin-left: auto;
}

.delete-subtask-button {
  padding: 2px 4px 2px 4px;
  margin-left: 6px;
  color: var(--highlight-color);
  border: 1px solid var(--highlight-color);
}

.delete-subtask-button:hover {
  color: #fff;
  background-color: var(--highlight-color);
}
