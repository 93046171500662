@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #8d69f1;
  --highlight-color: #d13267;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1em;
  background: var(--bg-color);
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  display: flex;
  padding-top: 0.4em;
  /* padding-left: .4em; */
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 10px auto;
}
label span {
  display: block;
  margin-bottom: 2px;
}
input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.warning-text {
  color: var(--highlight-color);
}

/*Button styles*/
.edit-btn-small {
  margin-left: auto;
  padding: 2px 4px 2px 4px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.edit-btn-small:hover {
  color: #fff;
  background-color: var(--primary-color);
}

.edit-button {
  margin-left: 10px;
}

.delete-btn-small {
  margin-left: auto;
  padding: 2px 4px 2px 4px;
  color: var(--highlight-color);
  border: 1px solid var(--highlight-color);
}

.delete-btn-small:hover {
  color: #fff;
  background-color: var(--highlight-color);
}

.delete-btn {
  color: var(--highlight-color);
  border: 1px solid var(--highlight-color);
}

.delete-btn:hover {
  color: #fff;
  background-color: var(--highlight-color);
}

.block-btn {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--primary-color);
  color: white;
  padding: 6px 8px;
  /* font-size: 16px; */
  cursor: pointer;
  text-align: center;
}

.block-btn:hover {
  background-color: #ddd;
  color: black;
}

/*List styles*/
.main-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;
}

.main-list div {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

/* Tool Tips */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Table basic styles */
tr {
}
th,
td {
  /* text-align: left;
  padding: 6px;
  border: 1px solid #ccc; */
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 200px;
  max-width: 700px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: var(--heading-color);
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 10px 8px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #a6a6a653;
}

.styled-table tbody tr:hover {
  background-color: var(--primary-color);
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--heading-color);
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
