.task-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 10px;
}

.task-list div {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.task-list a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  /*margin-left: auto;
  padding: 2px 4px 2px 4px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
   color: var(--highlight-color);
  border: 1px solid var(--highlight-color); */
}

.task-list button {
  text-decoration: none;
  margin-left: auto;
  padding: 4px 4px 4px 4px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  /* color: var(--highlight-color);
  border: 1px solid var(--highlight-color); */
}

.subtask-list {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  color: inherit;
  margin-top: 10px;
}

.add-button {
  padding: 2px 4px 2px 4px;
}