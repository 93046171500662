.App {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
}
.App .container {
  flex-grow: 1;
  /* padding: 0 160px; */
  /*padding: 0 10px; */
}
