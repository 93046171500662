
.card-library-list div{
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    color: inherit;
    margin-top: 10px;
  }
  
  .delete-button {
    margin-left: auto;
    padding: 2px 4px 2px 4px;
    color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
  }

  .delete-button:hover {
    color: #fff;
    background-color: var(--highlight-color);
  }