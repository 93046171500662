/* .navbar {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 60px;
}
.navbar .logo a {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
  text-decoration: none;
}
.navbar .logo img a {
  margin-right: 10px;
} */

/* before mobile menu styling changes */

nav {
  background: #772eff;
  padding: 10px 20px;
  color: #fff;
  display: flex;
  align-items: center;
}
nav p {
  margin: 0;
  margin-right: auto;
}
nav li {
  display: inline-block;
  margin: 0 0 0 16px;
  cursor: pointer;
}
nav a {
  color: #fff;
  text-decoration: none;
}
/* before mobile menu styling changes */

/* * {
  margin: 0;
  padding: 0;
} */

h1 {
  font-family: Lato;
  color: black;
  padding: 1em;
}
a {
  text-decoration: none;
  color: white;
}
.mobileMenuLinks ul {
  list-style: none;
  display: flex;
  justify-content: center;
  color: white;
}
.mobileMenuLinks li {
  margin: 1em;
}
.navigationBar {
  background: #282828;
  height: 50px;
}

.active {
  color: var(--primary-color);
}
.mobileNavButtonLines {
  height: 3px;
  width: 30px;
  background: white;
  display: block;
}
.mobileNavButton {
  display: none;
}
.mobileMenuLinks {
  display: none;
}

@media screen and (max-width: 5000px) {
  .mobileNavButton {
    display: flex;
    width: fit-content;
    margin-left: auto;
    height: 23px;
    flex-direction: column;
    justify-content: space-between;
  }
  .menuLinks {
    display: none;
  }
  .mobileMenuLinks {
    display: flex;
    background: #282828;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0.5em;
  }
  .mobileMenuLinks > li {
    text-align: right;
  }
  .mobileMenuLinks > li > a:hover {
    color: var(--highlight-color);
  }
  .mobileMenuLinks > li:hover {
    color: var(--highlight-color);
  }
  .navigationBar {
    display: flex;
    align-items: center;
    /* justify-content: right; */
  }
}
